<div class="login-container">
    <div data-zm="login-logo" zmid="login-logo" class="row">
        <div class="col text-center">
            <img width="250" alt="ZEN Master logo" class="align-middle" [src]="appService.whiteLogoUrl" />
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
                <div class="card-body">
                    <form data-zm="form-login" zmid="form-login" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <!-- Credentials Login -->
                        <div *ngIf="!ssos.length || showZenLogin">
                            <h3>{{ "SIGN_IN" | translate }}</h3>

                            <!-- Email -->
                            <div class="form-group">
                                <label for="email">{{ "EMAIL" | translate }}</label>
                                <input
                                    data-zm="form-email"
                                    zmid="form-email"
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Email"
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">{{ "EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Password -->
                            <div class="form-group">
                                <label for="password">{{ "PASSWORD" | translate }}</label>
                                <input
                                    data-zm="form-password"
                                    zmid="form-password"
                                    type="password"
                                    class="form-control"
                                    id="password"
                                    placeholder="Password"
                                    formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">{{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button [disabled]="loading" data-zm="form-submit" zmid="form-submit" type="submit" class="btn btn-primary">
                                    {{ "LOGIN" | translate }}
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                                </button>
                                <a class="float-end mt-2" routerLink="/forgot-password">{{ "FORGOT_PASSWORD" | translate }}?</a>
                            </div>
                            <div *ngIf="error" data-zm="form-error-alert" zmid="form-error-alert" class="alert alert-danger mb-2">{{ error }}</div>
                            <div class="form-group" *ngIf="ssos.length">
                                <a class="mt-2" (click)="toggleSSOLogin()" href="javascript:void(0)">{{ "SIGN_IN_SSO_TOGGLE" | translate }}</a>
                            </div>
                        </div>

                        <!-- SSO Login -->
                        <div *ngIf="ssos.length && !showZenLogin">
                            <h3>{{ "SIGN_IN_SSO" | translate }}</h3>

                            <div class="form-group" *ngFor="let sso of ssos">
                                <a class="btn btn-primary btn-block" (click)="ssoLogin(sso)" href="javascript:void(0)">
                                    {{ sso.name }}
                                </a>
                            </div>
                            <div *ngIf="error" class="alert alert-danger mb-2">{{ error }}</div>
                            <div *ngIf="accountSettings.allow_non_sso_login" class="form-group text-center">
                                <a data-zm="sign-in-zen" zmid="sign-in-zen" class="mt-2" (click)="toggleSSOLogin()" href="javascript:void(0)">{{
                                    "SIGN_IN_ZEN_TOGGLE" | translate
                                }}</a>
                            </div>
                        </div>

                        <div class="small mt-2 text-center">
                            This site is protected by reCAPTCHA. Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                        </div>
                    </form>
                </div>
            </div>
            <app-launch-page-footer></app-launch-page-footer>
        </div>
    </div>
</div>
