import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { SharedPagesAuthGuard } from "./guards/shared-pages-auth.guard";

// Services
import { SSOsResolverService } from "./pages/login/ssos-resolver.service";
import { CustomerSettingsResolverService } from "./pages/login/customer-settings-resolver.service";

// Components
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SetPasswordComponent } from "./pages/set-password/set-password.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { SearchComponent } from "./pages/search/search.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { StyleGuideComponent } from "./pages/style-guide/style-guide.component";

// Constants
import { Constants } from "./constants/constants";
import { environment } from "../environments/environment";

const authRoutes = [
    {
        path: Constants.urls.login,
        component: LoginComponent,
        resolve: {
            ssos: SSOsResolverService,
            settings: CustomerSettingsResolverService
        }
    },
    {
        path: Constants.urls.forgot_password,
        component: ForgotPasswordComponent
    },
    {
        path: Constants.urls.reset,
        component: ResetPasswordComponent
    },
    {
        path: Constants.urls.set_password,
        component: SetPasswordComponent,
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.search,
        component: SearchComponent,
        canActivate: [AuthGuard]
    }
];

const componentRoutes = [
    ...authRoutes,
    {
        path: Constants.urls.not_found,
        component: NotFoundComponent,
        canActivate: [AuthGuard]
    },
    {
        path: "style-guide",
        component: StyleGuideComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.dashboard,
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.DASH",
                    link: "https://zixidocumentation.atlassian.net/l/c/7gXSnqt9"
                }
            ]
        }
    }
];

const appRoutes: Routes = [
    ...componentRoutes,
    {
        path: Constants.urls.incidents,
        loadChildren: () => import("./pages/incidents/incidents.module").then(m => m.IncidentsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.grids,
        loadChildren: () => import("./pages/grids/grids.module").then(m => m.GridsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.clusters,
        loadChildren: () => import("./pages/clusters/clusters.module").then(m => m.ClustersModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.zecs,
        loadChildren: () => import("./pages/zecs/zecs.module").then(m => m.ZecsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.sources,
        loadChildren: () => import("./pages/sources/sources.module").then(m => m.SourcesModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.channels,
        loadChildren: () => import("./pages/channels/channels.module").then(m => m.ChannelsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.targets,
        loadChildren: () => import("./pages/targets/targets.module").then(m => m.TargetsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accounts,
        loadChildren: () => import("./pages/accounts/accounts.module").then(m => m.AccountsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.adminBase,
        redirectTo: Constants.urls.announcements_configurations
    },
    {
        path: Constants.urls.io_servers,
        loadChildren: () => import("./pages/io-servers/io-servers.module").then(m => m.IoServersModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.transformationBase,
        redirectTo: Constants.urls.transformation.transcoding_profiles
    },
    {
        path: Constants.urls.transformation.transcoding_profiles,
        loadChildren: () =>
            import("./pages/transcoding-profiles/transcoding-profiles.module").then(m => m.TranscodingProfilesModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.transformation.pid_mapping_profiles,
        loadChildren: () => import("./pages/pid-mappings/pid-mappings.module").then(m => m.PidMappingsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.automation,
        loadChildren: () => import("./pages/automation/automation.module").then(m => m.AutomationModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.reports,
        loadChildren: () => import("./pages/reports/reports.module").then(m => m.ReportsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.announcements_configurations,
        loadChildren: () =>
            import("./pages/announcements-configurations/announcements-configurations.module").then(
                m => m.AnnouncementsConfigurationsModule
            ),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.remote_access,
        loadChildren: () => import("./pages/remote-access/remote-access.module").then(m => m.RemoteAccessModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.maps,
        loadChildren: () => import("./pages/maps/map.module").then(m => m.MapModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.networks,
        loadChildren: () => import("./pages/networks/networks.module").then(m => m.NetworksModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.multi_viewer,
        loadChildren: () => import("./pages/multi-viewers/multi-viewer.module").then(m => m.MultiViewerModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.logsBase,
        redirectTo: Constants.urls.logs.events
    },
    {
        path: Constants.urls.logs.events,
        loadChildren: () => import("./pages/events/events.module").then(m => m.EventsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.logs.scte,
        loadChildren: () => import("./pages/scte/scte.module").then(m => m.ScteModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagementBase,
        redirectTo: Constants.urls.accountManagement.myAccount
    },
    {
        path: Constants.urls.accountManagement.myAccount,
        loadChildren: () =>
            import("./pages/account-management/my-account/my-account.module").then(m => m.MyAccountModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagement.sso,
        loadChildren: () => import("./pages/account-management/sso/ssos.module").then(m => m.SSOsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagement.users,
        loadChildren: () => import("./pages/account-management/users/users.module").then(m => m.UsersModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagement.userGroups,
        loadChildren: () =>
            import("./pages/account-management/user-groups/user-groups.module").then(m => m.UserGroupsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagement.roles,
        loadChildren: () => import("./pages/account-management/roles/roles.module").then(m => m.RolesModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configurationBase,
        redirectTo: Constants.urls.configuration.tags
    },
    {
        path: Constants.urls.configuration.tags,
        loadChildren: () => import("./pages/configuration/tags/tags.module").then(m => m.TagsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.apiKeys,
        loadChildren: () => import("./pages/configuration/api-keys/api-keys.module").then(m => m.ApiKeysModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.sshKeys,
        loadChildren: () => import("./pages/configuration/ssh-keys/ssh-keys.module").then(m => m.SshKeysModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.aws,
        loadChildren: () =>
            import("./pages/configuration/aws-accounts/aws-accounts.module").then(m => m.AwsAccountsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.azure,
        loadChildren: () =>
            import("./pages/configuration/azure-accounts/azure-accounts.module").then(m => m.AzureAccountsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.gcp,
        loadChildren: () =>
            import("./pages/configuration/gcp-accounts/gcp-accounts.module").then(m => m.GcpAccountsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.linode,
        loadChildren: () =>
            import("./pages/configuration/linode-accounts/linode-accounts.module").then(m => m.LinodeAccountsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.notifications,
        loadChildren: () =>
            import("./pages/configuration/notifications/notifications.module").then(m => m.NotificationsModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.eventsManagement,
        loadChildren: () =>
            import("./pages/configuration/events-management/events-management.module").then(
                m => m.EventsManagementModule
            ),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.customImage,
        loadChildren: () =>
            import("./pages/configuration/custom-image/custom-image.module").then(m => m.CustomImageModule),
        canLoad: [AuthGuard]
    },
    {
        path: Constants.urls.sharedBase,
        loadChildren: () => import("./pages/shared/shared-pages.module").then(m => m.SharedPagesModule),
        canLoad: [SharedPagesAuthGuard]
    },
    {
        path: "",
        component: DashboardComponent,
        pathMatch: "full",
        canActivate: [AuthGuard]
    },
    {
        path: "**",
        component: NotFoundComponent,
        pathMatch: "full",
        canActivate: [AuthGuard]
    }
];

if (environment.shouldShowComponentStories) {
    appRoutes.push({
        path: "component-stories",
        loadChildren: () =>
            import("./pages/components-stories/components-stories.module").then(m => m.ComponentsStoriesModule),
        canActivate: [AuthGuard]
    });
}

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            enableTracing: false, // <-- debugging purposes only
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "enabled"
            // paramsInheritanceStrategy: "always"
        })
    ],
    exports: [RouterModule]
})
export class AppRouting {}
