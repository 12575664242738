import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription, interval } from "rxjs";
import * as _ from "lodash";

import { AuthService } from "./../../services/auth.service";
import { UsersService } from "../../pages/account-management/users/users.service";
import { ResizeService } from "./../../services/resize.service";
import { StatusService } from "./../../services/status.service";
import { Constants } from "./../../constants/constants";
import { StatusSummary } from "./../../models/status";
import { Menu, NavigationPermissions, User } from "./../../models/shared";
import { NavigationService } from "./navigation.service";

interface Link {
    name: string;
    icon: string;
    router: string;
    isCollapsed?: boolean;
    permission?: boolean;
    subLinks?: {
        name: string;
        router: string;
        permission?: boolean;
    }[];
    activeCount?: string;
    status?: string[];
    errors?: number;
    warnings?: number;
    bgcolorclass?: string;
}

@Component({
    selector: "app-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit, OnDestroy {
    links: Link[];
    isOpen = false;
    isMobile = false;
    isContracted = false;
    isExpanded = false;
    isLoggedIn: Observable<boolean>;

    globalSearchVal: string;
    status: StatusSummary;

    private statusSubscription: Subscription;
    private statusRefreshSubscription: Subscription;
    private menuSubscription: Subscription;
    private navigationPermissionsSubscription: Subscription;

    private user: User;

    menu: Menu;
    navigationPermissions: NavigationPermissions;

    size: number;
    private resizeSubscription: Subscription;
    private navSubscription: Subscription;

    constructor(
        private authService: AuthService,
        private rs: ResizeService,
        private userService: UsersService,
        private navigationService: NavigationService,
        private ss: StatusService,
        private router: Router
    ) {}

    ngOnInit() {
        this.navSubscription = this.navigationService.toggle$.subscribe(() => this.toggle());

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;
        });

        // Resize
        this.resizeSubscription = this.rs.getCurrentSize.subscribe((x: number | string) => {
            if (typeof x === "number") this.size = x;
            else if (typeof x === "string") this.size = parseInt(x, 10);
            if (this.size >= 6) {
                this.isExpanded = true;
                this.isContracted = false;
                this.isMobile = false;
            } else if (this.size === 5) {
                this.isExpanded = false;
                this.isContracted = true;
                this.isMobile = false;
            } else {
                this.isExpanded = false;
                this.isContracted = false;
                this.isMobile = true;
            }
            this.navigationService.setNavExpanded(this.isExpanded);
        });

        // Status
        this.statusSubscription = this.ss.status.subscribe(status => {
            this.status = status;
            this.countNotifications();
        });

        // Logged In
        this.isLoggedIn = this.authService.isLoggedIn;

        // Permissions
        this.navigationPermissionsSubscription = this.userService.getNavigationPermissions
            .pipe()
            .subscribe((permissions: NavigationPermissions) => {
                this.navigationPermissions = permissions;
                this.setLinks();
            });

        // Menu
        this.menuSubscription = this.userService.getMenuPermissions.pipe().subscribe((menu: Menu) => {
            this.menu = menu;
            this.setLinks();
        });

        // Nav Expanded?
        const navExpanded = localStorage.getItem("navExpanded");
        if (navExpanded === "false" && !this.isMobile) {
            this.contractNav();
        }

        // Start Status Auto Refresh
        setTimeout(() => {
            this.ss.refreshStatus();
            this.startStatusRefresh();
        }, 0);
    }

    ngOnDestroy() {
        this.navSubscription.unsubscribe();
        this.resizeSubscription.unsubscribe();
        this.statusSubscription.unsubscribe();
        this.menuSubscription.unsubscribe();
        this.navigationPermissionsSubscription.unsubscribe();
        this.stopStatusRefresh();
    }

    setLinks() {
        this.links = [
            {
                name: "NAVIGATION.DASHBOARD",
                icon: "tachometer",
                router: Constants.urls.dashboard,
                permission: true
            },
            {
                name: "NAVIGATION.INCIDENTS",
                icon: "file-exclamation",
                router: Constants.urls.incidents,
                permission: true,
                activeCount: "active_incidents"
            },
            {
                name: "NAVIGATION.GRIDS",
                icon: "grid",
                router: Constants.urls.grids,
                permission: true
            },
            {
                name: "NAVIGATION.ZECS",
                icon: "rss",
                router: Constants.urls.zecs,
                status: ["feeders", "receivers", "zecs"],
                permission: this.menu?.zecs ? true : false
            },
            {
                name: "NAVIGATION.BROADCASTERS",
                icon: "cloud",
                router: Constants.urls.clusters,
                status: ["broadcasters"],
                permission: this.menu?.clusters ? true : false
            },
            {
                name: "NAVIGATION.SOURCES",
                icon: "video",
                router: Constants.urls.sources,
                status: ["sources", "mediaconnect_sources"],
                permission: this.menu?.sources ? true : false
            },
            {
                name: "NAVIGATION.CHANNELS",
                icon: "project-diagram",
                router: Constants.urls.channels,
                status: [
                    "adaptive_channels",
                    "delivery_channels",
                    "mediaconnect_flows",
                    "failover_channel",
                    "medialive_channels"
                ],
                permission: this.menu?.adaptive_channels || this.menu?.delivery_channels ? true : false
            },
            {
                name: "NAVIGATION.TARGETS",
                icon: "share",
                router: Constants.urls.targets,
                status: [
                    "publishing_targets",
                    "zixi_pull",
                    "zixi_push",
                    "rtmp_push",
                    "push",
                    "pull",
                    "mediaconnect",
                    "mediastore",
                    "http",
                    "azure",
                    "gcp",
                    "s3",
                    "udp_rtp",
                    "ndi_targets",
                    "srt_targets",
                    "rist",
                    "cdi",
                    "jpegxs"
                ],
                permission: this.menu?.targets ? true : false
            },
            {
                name: "LIVE_EVENTS",
                icon: "calendar-days",
                router: "live-events",
                status: [],
                permission:
                    (this.user.is_admin ||
                        this.user.is_objects_manager ||
                        this.user.is_zixi_admin ||
                        this.user.is_zixi_support_write ||
                        this.user.is_zixi_support) &&
                    this.menu?.live_events
            },
            {
                name: "MULTI_VIEWERS",
                icon: "display",
                router: Constants.urls.multi_viewer,
                status: [],
                permission: Boolean(this.menu?.multi_viewer)
            },
            {
                name: "NAVIGATION.TRANSFORMATIONS",
                icon: "magic",
                router: Constants.urls.transformationBase,
                permission: this.menu?.transcoding_profiles ? true : false,
                isCollapsed: true,
                subLinks: [
                    {
                        name: "NAVIGATION.TRANSCODING_PROFILES",
                        router: Constants.urls.transformation.transcoding_profiles,
                        permission: true
                    },
                    {
                        name: "NAVIGATION.PID_MAPPING_PROFILES",
                        router: Constants.urls.transformation.pid_mapping_profiles,
                        permission: true
                    }
                ]
            },
            {
                name: "NAVIGATION.LOGS",
                icon: "exclamation-triangle",
                router: Constants.urls.logsBase,
                permission: true,
                isCollapsed: true,
                subLinks: [
                    {
                        name: "NAVIGATION.EVENTS",
                        router: Constants.urls.logs.events,
                        permission: true
                    },
                    {
                        name: "NAVIGATION.SCTE_35",
                        router: Constants.urls.logs.scte,
                        permission: true
                    },
                    {
                        name: "NAVIGATION.REPORTS",
                        router: Constants.urls.reports,
                        permission: this.menu?.reports ? true : false
                    }
                ]
            },
            {
                name: "NAVIGATION.AUTOMATION",
                icon: "play-circle",
                router: Constants.urls.automation,
                permission: !!(
                    this.user.is_admin ||
                    this.user.is_objects_manager ||
                    this.user.is_zixi_admin ||
                    this.user.is_zixi_support_write ||
                    this.user.is_zixi_support
                )
            },
            {
                name: "NAVIGATION.MAPS",
                icon: "map-marked-alt",
                router: Constants.urls.maps,
                permission: true
            },
            {
                name: "NAVIGATION.NETWORKS",
                icon: "chart-network",
                router: Constants.urls.networks,
                permission: true
            },
            {
                name: "NAVIGATION.ACCOUNT_MANAGEMENT",
                icon: "users-cog",
                router: Constants.urls.accountManagementBase,
                permission: true,
                isCollapsed: true,
                subLinks: [
                    {
                        name: "NAVIGATION.MY_ACCOUNT",
                        router: Constants.urls.accountManagement.myAccount,
                        permission: true
                    },
                    {
                        name: "NAVIGATION.SSO",
                        router: Constants.urls.accountManagement.sso,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.USERS",
                        router: Constants.urls.accountManagement.users,
                        permission: this.menu?.user_management ? true : false
                    },
                    {
                        name: "NAVIGATION.USER_GROUPS",
                        router: Constants.urls.accountManagement.userGroups,
                        permission: this.menu?.user_management ? true : false
                    },
                    {
                        name: "NAVIGATION.ROLES",
                        router: Constants.urls.accountManagement.roles,
                        permission: this.menu?.user_management ? true : false
                    }
                ]
            },
            {
                name: "NAVIGATION.CONFIGURATION",
                icon: "cogs",
                router: Constants.urls.configurationBase,
                isCollapsed: true,
                permission: this.menu?.settings ? true : false,
                subLinks: [
                    {
                        name: "NAVIGATION.TAGS",
                        router: Constants.urls.configuration.tags,
                        permission: this.navigationPermissions.resources
                    },
                    {
                        name: "NAVIGATION.EVENTS_MANAGEMENT",
                        router: Constants.urls.configuration.eventsManagement,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.NOTIFICATIONS",
                        router: Constants.urls.configuration.notifications,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.API_KEYS",
                        router: Constants.urls.configuration.apiKeys,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.SSH_KEYS",
                        router: Constants.urls.configuration.sshKeys,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.AMAZON_AWS",
                        router: Constants.urls.configuration.aws,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.MICROSOFT_AZURE",
                        router: Constants.urls.configuration.azure,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.GOOGLE_GCP",
                        router: Constants.urls.configuration.gcp,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.AKAMAI_LINODE",
                        router: Constants.urls.configuration.linode,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    },
                    {
                        name: "NAVIGATION.AWS_AMIS",
                        router: Constants.urls.configuration.customImage,
                        permission: !!(
                            this.user.is_admin ||
                            this.user.is_zixi_admin ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_support
                        )
                    }
                ]
            },
            {
                name: "NAVIGATION.REMOTE_ACCESS",
                icon: "sign-in-alt",
                router: Constants.urls.remote_access,
                status: ["remote_access"],
                permission: this.menu?.remote_access ? true : false
            },
            {
                name: "NAVIGATION.ADMIN",
                icon: "user-circle",
                router: Constants.urls.accounts,
                isCollapsed: true,
                permission:
                    this.user.is_zixi_admin ||
                    this.menu?.domains ||
                    this.user.is_zixi_support ||
                    this.user.is_zixi_support_write ||
                    this.user.is_zixi_admin
                        ? true
                        : false,
                bgcolorclass: "bg-dark impersonating",
                subLinks: [
                    {
                        name: "NAVIGATION.ANNOUNCEMENTS_CONFIGURATIONS",
                        router: Constants.urls.announcements_configurations,
                        permission: !!this.user.is_zixi_admin
                    },
                    {
                        name: "NAVIGATION.ACCOUNTS",
                        router: Constants.urls.accounts,
                        permission:
                            this.menu?.domains ||
                            this.user.is_zixi_support ||
                            this.user.is_zixi_support_write ||
                            this.user.is_zixi_admin
                                ? true
                                : false
                    },
                    {
                        name: "NAVIGATION.IO_SERVERS",
                        router: Constants.urls.io_servers,
                        permission: this.menu ? this.menu.domains : false
                    }
                ]
            }
        ];

        this.countNotifications();
    }

    startStatusRefresh() {
        this.statusRefreshSubscription = interval(60000).subscribe(() => {
            this.ss.refreshStatus();
        });
    }

    stopStatusRefresh() {
        this.statusRefreshSubscription.unsubscribe();
    }

    toggle(ignore?: boolean) {
        if (ignore) return;
        if (this.isMobile) this.isOpen = !this.isOpen;
        else {
            this.isContracted = !this.isContracted;
            this.isExpanded = !this.isExpanded;
            localStorage.setItem("navExpanded", this.isExpanded.toString());
            this.navigationService.setNavExpanded(this.isExpanded);
        }
    }

    contractNav() {
        this.isContracted = true;
        this.isExpanded = false;
        this.navigationService.setNavExpanded(this.isExpanded);
    }

    globalSearch() {
        if (this.isMobile) this.isOpen = !this.isOpen;
        this.router.navigate(["/search"], { queryParams: { q: this.globalSearchVal } });
        this.globalSearchVal = null;
    }

    countNotifications() {
        _.each(this.links, link => {
            if (!link.status) return;

            link.errors = _.reduce(
                link.status,
                (count, key) => {
                    if (this.status && this.status[key] && this.status[key].error)
                        return count + this.status[key].error;
                    return count;
                },
                0
            );

            link.warnings = _.reduce(
                link.status,
                (count, key) => {
                    if (this.status && this.status[key] && this.status[key].warning)
                        return count + this.status[key].warning;
                    return count;
                },
                0
            );
        });
    }

    private warningNotMuted = JSON.stringify([
        {
            columnHeader: "Status",
            columnType: "select",
            rawValue: ["Warning"],
            value: ["Warning"],
            operatorType: "IS"
        },
        {
            columnHeader: "Muted",
            columnType: "select",
            rawValue: ["No"],
            value: ["No"],
            operatorType: "IS"
        }
    ]);

    showActiveObjectList(link) {
        if (link.router === Constants.urls.incidents) {
            localStorage.setItem(
                "incident.cacheFilter",
                JSON.stringify([
                    {
                        columnHeader: "Ongoing",
                        columnType: "select",
                        operatorType: "IS",
                        options: ["Yes", "No"],
                        rawValue: ["Yes"],
                        value: ["Yes"]
                    }
                ])
            );
            this.forceLoadRoute([Constants.urls.incidents]);
        }
    }

    showWarningsObjectList(link) {
        if (link.router === "zec") {
            localStorage.setItem("zec.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.zecs]);
        }
        if (link.router === "clusters") {
            localStorage.setItem("cluster.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.clusters]);
        }
        if (link.router === "sources") {
            localStorage.setItem("source.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.sources]);
        }
        if (link.router === "channels") {
            localStorage.setItem("channel.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.channels]);
        }
        if (link.router === "targets") {
            localStorage.setItem("target.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.targets]);
        }
        if (link.router === "remote-access") {
            localStorage.setItem("remoteAccess.cacheFilter", this.warningNotMuted);
            this.forceLoadRoute([Constants.urls.remote_access]);
        }
    }

    private errorNotMuted = JSON.stringify([
        {
            columnHeader: "Status",
            columnType: "select",
            rawValue: ["Error"],
            value: ["Error"],
            operatorType: "IS"
        },
        {
            columnHeader: "Muted",
            columnType: "select",
            rawValue: ["No"],
            value: ["No"],
            operatorType: "IS"
        }
    ]);

    showErrorsObjectList(link) {
        if (link.router === "zec") {
            localStorage.setItem("zec.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.zecs]);
        }
        if (link.router === "clusters") {
            localStorage.setItem("cluster.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.clusters]);
        }
        if (link.router === "sources") {
            localStorage.setItem("source.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.sources]);
        }
        if (link.router === "channels") {
            localStorage.setItem("channel.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.channels]);
        }
        if (link.router === "targets") {
            localStorage.setItem("target.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.targets]);
        }
        if (link.router === "remote-access") {
            localStorage.setItem("remoteAccess.cacheFilter", this.errorNotMuted);
            this.forceLoadRoute([Constants.urls.remote_access]);
        }
    }

    forceLoadRoute(url: string[]) {
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
            this.router.navigate(url);
        });
    }
}
