import { Component, OnInit, OnDestroy, ComponentRef } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import * as _ from "lodash";
//
import { StatusService } from "../../services/status.service";
import { UsersService } from "../account-management/users/users.service";
import { AnnouncementsService } from "../../services/announcements.service";
import { StatusSummary, OpenIssue } from "../../models/status";
import { Announcements } from "@zixi/models";
import { KeyMap, Menu } from "src/app/models/shared";
import { TitleService } from "../../services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/services/shared.service";
import { Incident } from "../incidents/incident";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { IncidentsNameColumnComponent } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.component";
import { assignIncidentNameInputsFactory } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.table-adapter.ts";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import moment from "moment";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { DatePipe } from "@angular/common";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { IncidentsService } from "../incidents/incidents.service";
import { Router } from "@angular/router";
import { Constants } from "src/app/constants/constants";
import { ZxEventObjectComponent } from "src/app/components/shared/zx-event-object/zx-event-object.component";
//
import { TourSteps } from "src/app/constants/tour-steps";
import { TourService } from "ngx-ui-tour-md-menu";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    templateUrl: "./dashboard.component.html",
    providers: [AnnouncementsService, { provide: "isCriticalAnnouncements", useValue: false }, DatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
    status: StatusSummary;
    issues: OpenIssue[];
    private issuesBS$ = new BehaviorSubject<OpenIssue[]>([]);
    nonCriticalAnnouncements$: Observable<Announcements[]>;

    menu: Menu;

    loadingIssues = true;
    loadingStatus = true;

    page = 1;
    pageSize = 10;

    private statusSubscription: Subscription;
    private menuSubscription: Subscription;
    private incidentsSubscription: Subscription;

    constants = Constants;
    private tourSteps = TourSteps.dashboardFeatures;

    private statusMap = {
        feeders: "zixi_edge_devices",
        receivers: "zixi_edge_devices",
        zecs: "zixi_edge_devices",
        broadcasters: "broadcasters",
        adaptive_channels: "channels",
        delivery_channels: "channels",
        mediaconnect_flows: "channels",
        failover_channel: "channels",
        medialive_channels: "channels",
        publishing_targets: "targets",
        rtmp_push: "targets",
        zixi_push: "targets",
        zixi_pull: "targets",
        srt_targets: "targets",
        ndi_targets: "targets",
        rist: "targets",
        udp_rtp: "targets",
        sources: "sources",
        mediaconnect_sources: "sources",
        remote_access: "remote_access"
    };

    tableEventsColumnsSchema: TableSchema<KeyMap<OpenIssue>>[] = [
        {
            header: this.translate.instant("RESOURCE"),
            columnDef: "resource",
            visible: true,
            sticky: 1,
            component: ZxEventObjectComponent,
            assignComponentsInputs: (compRef: ComponentRef<ZxEventObjectComponent>, row: KeyMap<OpenIssue>) => {
                const ref = compRef.instance;
                const props = {
                    item: row
                };
                for (const key in props) {
                    const value = props[key];
                    ref[key] = value;
                }
            },
            sortBy: (row: KeyMap<OpenIssue>) => row.object_name
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "MESSAGE",
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<OpenIssue>>(
                row => row.short_message,
                row => row.short_message,
                () => true
            ),
            sortBy: (row: KeyMap<OpenIssue>) => row.short_message
        }
    ];

    constructor(
        private statusService: StatusService,
        private announcementsService: AnnouncementsService,
        private userService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService,
        public sharedService: SharedService,
        private datePipe: DatePipe,
        private is: IncidentsService,
        private router: Router,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {
        // Set Title
        this.titleService.setTitle("DASHBOARD", "");
        this.nonCriticalAnnouncements$ = this.announcementsService.announcements$;
    }

    ngOnInit() {
        // Menu
        this.menuSubscription = this.userService.getMenuPermissions.pipe().subscribe((menu: Menu) => {
            this.menu = menu;
        });

        this.statusSubscription = this.statusService.refreshStatus().subscribe(status => {
            this.prepStatus(status);
            this.loadingStatus = false;
        });

        this.statusService.getIssues().then((issues: OpenIssue[]) => {
            this.issues = issues;
            if (this.issues) {
                this.prepEventsTableData();
            }
            this.loadingIssues = false;
        });

        this.incidentsSubscription = this.is.incidents.subscribe(incidents => {
            this.incidents = incidents;
            if (this.incidents) {
                this.prepTableData();
            }
        });

        this.is.getIncidents(true);
        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        if (this.statusSubscription) this.statusSubscription.unsubscribe();
        if (this.menuSubscription) this.menuSubscription.unsubscribe();
        if (this.incidentsSubscription) this.incidentsSubscription.unsubscribe();
    }

    prepStatus(status: StatusSummary) {
        const prev = _.reduce(
            status,
            (ns, stats, key) => {
                if (!this.statusMap[key]) return ns;
                if (!ns[this.statusMap[key]]) {
                    ns[this.statusMap[key]] = Object.assign({}, stats);
                } else {
                    _.each(stats, (v, k) => {
                        ns[this.statusMap[key]][k] += v;
                    });
                }
                return ns;
            },
            new StatusSummary()
        );
        this.status = prev;
        return this.status;
    }

    dismissAnnouncement(announcement: Announcements) {
        this.announcementsService.dismissAnnouncement(announcement);
    }

    // Incidents
    incidents: Incident[] = [];
    private incidentsBS$ = new BehaviorSubject<Incident[]>([]);

    toDate: moment.Moment = moment();
    fromDate: moment.Moment = moment().subtract(7, "days");
    dateFormat = "M/d/YY, h:mm a";

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: IncidentsNameColumnComponent,
            assignComponentsInputs: assignIncidentNameInputsFactory,
            sortBy: (row: KeyMap<Incident>) => row.name,
            textValue: (row: KeyMap<Incident>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("START_TIME"),
            columnDef: "startTime",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<Incident>>(
                row => row.start_time,
                this.dateFormat // format
            ),
            sortBy: (row: KeyMap<Incident>) => new Date(row.start_time).getTime(),
            textValue: (row: KeyMap<Incident>) => {
                if (row.start_time !== "0000-00-00 00:00:00") {
                    const startTimeDate = new Date(row.start_time);
                    const dateISOString = startTimeDate.toISOString();
                    return this.datePipe.transform(dateISOString, this.dateFormat);
                }
                return "-";
            }
        },
        {
            header: this.translate.instant("DURATION(DD:HH:MM:SS)"),
            columnDef: "duration",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row._frontData.duration,
                row => row._frontData.duration,
                () => true
            ),
            sortBy: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            textValue: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            columnFilterType: ColumnFilterType.DURATION,
            columnFilterValue: (row: Incident) => row._frontData.durationSort?.asSeconds()
        },
        {
            header: this.translate.instant("ROOT_CAUSE"),
            columnDef: "rootCause",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row.likely_cause || "-",
                row => row.likely_cause || "-",
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.likely_cause || "-",
            textValue: (row: KeyMap<Incident>) => row.likely_cause || "-",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("#_OF_OBJECTS_AFFECTED"),
            columnDef: "objectsCount",
            width: 60,
            visible: true,
            align: "right",
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => this.getObjectCountColumnTextByRow(row),
                row => this.getObjectCountColumnTextByRow(row),
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.objectsCount,
            textValue: (row: KeyMap<Incident>) => this.getObjectCountColumnTextByRow(row),
            columnFilterType: ColumnFilterType.NUMBER
        }
    ];

    get incidents$() {
        return this.incidentsBS$.asObservable();
    }

    private prepTableData() {
        if (this.incidents) {
            let incidents: Incident[] = [...this.incidents];

            incidents = incidents.filter(i => !i.end_time && !i.is_zixi);

            this.sharedService.sort(incidents, "start_time", "desc");
            this.incidentsBS$.next(incidents);
        }
    }

    private prepEventsTableData() {
        if (this.issues) {
            let issues = [...this.issues];
            this.issuesBS$.next(issues);
        }
    }

    get events$() {
        return this.issuesBS$.asObservable();
    }

    private getObjectCountColumnTextByRow(incident: Incident) {
        return incident.objectsCount ? incident.objectsCount.toString() : "-";
    }

    selectRow = (selectedIncident: Incident) => {
        this.router.navigate([Constants.urls.incidents, selectedIncident.id]);
    };
}
