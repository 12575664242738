import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { ForgotPasswordService } from "./forgot-password.service";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent {
    emailAddress: string;
    error: string;
    done = false;
    loading = false;
    submitted = false;

    @ViewChild("form", { static: true }) form: NgForm;

    constructor(private forgotPasswordService: ForgotPasswordService, public appService: AppService) {}

    async onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        try {
            await this.forgotPasswordService.forgot(this.emailAddress);
            this.done = true;
        } catch (_) {
            this.loading = false;
        }
    }
}
