<div class="d-flex justify-content-start" *ngIf="sources">
    <div *ngFor="let source of sources" class="position-relative">
        <zx-status-icon
            class="me-1"
            icon="square"
            container="body"
            triggers="mouseenter:mouseleave"
            [model]="source"
            [showTooltip]="false"
            (mouseenter)="hoveredSource = source"
            (mouseleave)="hoveredSource = null"
            [ngbPopover]="popoverContent"
        ></zx-status-icon>
    </div>

    <ng-template #popoverContent>
        <div class="thumbnail-wrapper">
            <app-source-thumbnail [source]="hoveredSource" [info]="true" [grid]="true"></app-source-thumbnail>
        </div>
    </ng-template>
</div>
