import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { filter, take } from "rxjs/operators";

import { ResetPasswordService } from "./reset-password.service";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
    password: string;
    confirmPassword: string;
    token: string;
    error: string;
    customError: string;
    done = false;
    loading = false;
    submitted = false;

    @ViewChild("form", { static: true }) form: NgForm;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: ResetPasswordService,
        public appService: AppService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(
                filter(params => params.token),
                take(1)
            )
            .subscribe(params => {
                this.token = params.token;
            });
    }

    async onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        if (this.password !== this.confirmPassword) {
            this.form.controls.confirmPassword.setErrors({ passwordMismatch: true });
            return;
        }

        this.loading = true;
        this.resetPasswordService.reset(this.password, this.token).subscribe(
            () => {
                this.router.navigateByUrl("/login");
            },
            error => {
                this.error = error.statusText;
                this.loading = false;
            }
        );
    }
}
