<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "CONFIRM_STAGE_CONTROL_CHANGES" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="list-container overflow-y-hidden mb-2" *ngIf="liveEvents.length > 0 ? true : loading">
            <!-- Table -->
            <app-table-list
                [tableName]="'stage_control'"
                [displayTableName]="'LIVE_EVENTS' | translate"
                [showFilter]="false"
                [selectable]="false"
                [expanded]="true"
                [showColumnsSelection]="false"
                [canDisableCheckboxes]="true"
                [showReport]="false"
                [showPagination]="false"
                [refreshing]="loading"
                [data]="liveEvents"
                [tableSchema]="LiveEventsTableColumnsSchema"
                [(selectedRows)]="selectedLiveEvents"
            >
            </app-table-list>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
        <button
            type="button"
            class="btn btn-primary"
            [ngClass]="{ 'btn-danger': false }"
            ngbAutofocus
            (click)="submit()"
            [disabled]="disableSubmit || !selectedLiveEvents.length"
        >
            <span *ngIf="actionMode === liveEventActions.ManualMode">{{ "MANUAL" | translate }} {{ "MODE" | translate }}</span>
            <span *ngIf="actionMode === liveEventActions.NextStage">{{ "START" | translate }} {{ "NEXT_STAGE" | translate }}</span>
            <span *ngIf="actionMode === liveEventActions.EndEvent">{{ "END" | translate }} {{ "EVENTS" | translate }}</span>
        </button>
    </div>
</div>
