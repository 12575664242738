import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
    selector: "zx-live-event-clip-table",
    templateUrl: "./live-event-clip-table.component.html"
})
export class LiveEventClipTableComponent {
    @ViewChild("form") form: NgForm;
    @Input() clips: { name: string; url: string }[] = [];
    @Input() submitted: boolean;
    @Output() clipsChanged = new EventEmitter<{ name: string; url: string }[]>();

    addClip() {
        this.clips.push({ name: null, url: null });
        this.clipsChanged.emit(this.clips);
    }

    deselectClip(deselectIndex) {
        this.clips = this.clips.filter((_, index) => index !== deselectIndex);
        this.clipsChanged.emit(this.clips);
    }

    trackByIndex(index) {
        return index;
    }
}
