import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Stage } from "../../pages/live-event-form/live-event-form.component";
import _ from "lodash";

@Component({
    selector: "zx-live-event-stage-table",
    templateUrl: "./live-event-stage-table.component.html"
})
export class LiveEventStageTableComponent implements OnInit, OnChanges {
    @ViewChild("form") form: NgForm;
    @Input() stages: (Stage & { startTime?: Date; daysFromEvent?: number })[] = [
        { name: null, duration: 0, force_channel_slate: false, clip_id: null }
    ];
    @Input() eventStartTime: string; //Should be a date string
    @Input() clips: { name: string; url: string }[] = [];
    @Input() submitted: boolean;
    @Input() isStagingAuto = true;
    @Output() stagesChanged = new EventEmitter<Stage[]>();

    duplicateNamesIndex: number[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.eventStartTime) this.recalculateEventsStartTime();
    }

    ngOnInit() {
        this.validateAndEmitStage(true);
    }

    recalculateEventsStartTime() {
        const eventStartTimeAsDate = new Date(this.eventStartTime);
        this.stages.forEach((stage, index) => {
            if (!index) {
                stage.startTime = _.cloneDeep(eventStartTimeAsDate);
            } else {
                stage.startTime = _.cloneDeep(this.stages[index - 1].startTime);
                if (this.stages[index - 1].duration) {
                    stage.startTime.setMinutes(stage.startTime.getMinutes() + this.stages[index - 1].duration);
                }
                stage.daysFromEvent = stage.startTime.getDay() - eventStartTimeAsDate.getDay();
            }
        });
    }

    addStage() {
        this.stages.push({
            name: null,
            duration: null,
            force_channel_slate: false,
            clip_id: null
        });
        this.recalculateEventsStartTime();
        this.stagesChanged.emit(this.stages);
    }

    deselectStage(deselectIndex) {
        this.stages = this.stages.filter((_, index) => index !== deselectIndex);
        this.validateAndEmitStage(true);
    }

    validateAndEmitStage(nameChanged?: boolean) {
        if (nameChanged) {
            this.findDuplicateNamesIndex();
            setTimeout(() => {
                for (let i = 0; i < this.stages.length; i++) {
                    const stageNameControl = this.form.form.controls[`stageName_${i}`];
                    if (this.duplicateNamesIndex.includes(i)) {
                        stageNameControl.setErrors({ notUnique: true });
                    } else if (stageNameControl.value === null) {
                        stageNameControl.setErrors({ required: true });
                    } else {
                        stageNameControl.setErrors(null);
                    }
                }
            });
        }
        this.recalculateEventsStartTime();
        this.stagesChanged.emit(this.stages);
    }

    findDuplicateNamesIndex() {
        this.duplicateNamesIndex = [];
        for (const [index, stage] of this.stages.entries()) {
            if (stage.name !== null) {
                for (let i = index + 1; i < this.stages.length; i++) {
                    const otherStage = this.stages[i];
                    if (stage.name === otherStage.name && index !== i) {
                        this.duplicateNamesIndex.push(...[index, i]);
                    }
                }
            }
        }
        this.duplicateNamesIndex = [...new Set(this.duplicateNamesIndex)];
    }
}
