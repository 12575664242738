<div class="reset-container">
    <div class="row">
        <div class="col text-center">
            <img width="250" alt="ZEN Master logo" class="align-middle" [src]="appService.whiteLogoUrl" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
                <div class="card-body">
                    <form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
                        <h3>{{ "RESET_PASSWORD" | translate }}</h3>
                        <p>{{ "ENTER_AND_CONFIRM_YOUR_NEW_PASSWORD" | translate }}</p>

                        <!-- New Password -->
                        <div class="form-group">
                            <label for="newPassword" [ngClass]="{ 'is-invalid': form.submitted && passwordInput.errors }">
                                {{ "NEW" | translate }} {{ "PASSWORD" | translate }}
                                <fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <input
                                autocomplete="cc-csc"
                                type="password"
                                class="form-control"
                                id="password"
                                name="password"
                                [(ngModel)]="password"
                                placeholder="New Password"
                                [ngClass]="{ 'is-invalid': form.submitted && passwordInput.errors }"
                                #passwordInput="ngModel"
                                required
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                            />
                            <div *ngIf="passwordInput.invalid" class="invalid-feedback">
                                <div *ngIf="passwordInput.errors.required">
                                    {{ "NEW" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}
                                </div>
                                <div *ngIf="passwordInput.errors.pattern">
                                    {{ "PASSWORD_PATTERN" | translate }}
                                </div>
                            </div>
                        </div>

                        <!-- Confirm Password -->
                        <div class="form-group">
                            <label for="confirmPassword" [ngClass]="{ 'is-invalid': form.submitted && confirmPasswordInput.errors }">
                                {{ "CONFIRM" | translate }} {{ "PASSWORD" | translate }}
                                <fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <input
                                autocomplete="cc-csc"
                                type="password"
                                class="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                [(ngModel)]="confirmPassword"
                                placeholder="Confirm Password"
                                [ngClass]="{ 'is-invalid': form.submitted && confirmPasswordInput.errors }"
                                #confirmPasswordInput="ngModel"
                                required
                            />
                            <div *ngIf="confirmPasswordInput.invalid" class="invalid-feedback">
                                <div *ngIf="confirmPasswordInput.errors.required">
                                    {{ "CONFIRM" | translate }} {{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}
                                </div>
                                <div *ngIf="confirmPasswordInput.errors.passwordMismatch">{{ "PASSWORD_MISMATCH" | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button [disabled]="loading" zmid="form-submit" type="submit" class="btn btn-primary">
                                {{ "SUBMIT" | translate }}
                                <span *ngIf="loading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
                    </form>
                </div>
            </div>
            <app-launch-page-footer></app-launch-page-footer>
        </div>
    </div>
</div>
