// Core
import { NgModule, ErrorHandler, APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DecimalPipe, TitleCasePipe } from "@angular/common";

// Interceptors
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { ErrorInterceptor } from "./helpers/error.interceptor";

// Ng Bootstrap
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Modules
import { FontAwesome } from "./modules/font-awesome/font-awesome.module";
import { SharedModule } from "./modules/shared/shared.module";
import { LiveEventsModule } from "./modules/live-events/live-events.module";
import { ModalModule } from "./components/shared/modals/modal.module";
import { DashboardModule } from "./pages/dashboard/dashboard.module";
import { SearchModule } from "./pages/search/search.module";
import { BroadcastersModule } from "./components/broadcasters/broadcasters.module";
import { SourcesModule } from "./pages/sources/sources.module";
import { EventsModule } from "./pages/events/events.module";
import { ScteModule } from "./pages/scte/scte.module";
import { GridsModule } from "./pages/grids/grids.module";
import { ChannelsModule } from "./pages/channels/channels.module";
import { TargetsModule } from "./pages/targets/targets.module";
import { ClustersModule } from "./pages/clusters/clusters.module";
import { TranscodingProfilesModule } from "./pages/transcoding-profiles/transcoding-profiles.module";
import { PidMappingsModule } from "./pages/pid-mappings/pid-mappings.module";
import { EventsManagementModule } from "./pages/configuration/events-management/events-management.module";
import { TagsModule } from "./pages/configuration/tags/tags.module";
import { SshKeysModule } from "./pages/configuration/ssh-keys/ssh-keys.module";
import { ApiKeysModule } from "./pages/configuration/api-keys/api-keys.module";
import { AwsAccountsModule } from "./pages/configuration/aws-accounts/aws-accounts.module";
import { AzureAccountsModule } from "./pages/configuration/azure-accounts/azure-accounts.module";
import { GcpAccountsModule } from "./pages/configuration/gcp-accounts/gcp-accounts.module";
import { LinodeAccountsModule } from "./pages/configuration/linode-accounts/linode-accounts.module";
import { NotificationsModule } from "./pages/configuration/notifications/notifications.module";
import { AutomationModule } from "./pages/automation/automation.module";
import { ReportsModule } from "./pages/reports/reports.module";
import { MyAccountModule } from "./pages/account-management/my-account/my-account.module";
import { UsersModule } from "./pages/account-management/users/users.module";
import { UserGroupsModule } from "./pages/account-management/user-groups/user-groups.module";
import { RolesModule } from "./pages/account-management/roles/roles.module";
import { RemoteAccessModule } from "./pages/remote-access/remote-access.module";
import { SSOsModule } from "./pages/account-management/sso/ssos.module";
import { AccountsModule } from "./pages/accounts/accounts.module";
import { IoServersModule } from "./pages/io-servers/io-servers.module";
import { MapModule } from "./pages/maps/map.module";
import { IncidentsModule } from "./pages/incidents/incidents.module";
import { AnnouncementsConfigurationsModule } from "./pages/announcements-configurations/announcements-configurations.module";
import { SharedPagesModule } from "./pages/shared/shared-pages.module";
//
import { DragulaModule } from "ng2-dragula";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxSliderModule } from "ngx-slider-v2";
// Components
import { AppComponent } from "./app.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { CriticalAnnouncementsComponent } from "./components/critical-announcements/critical-announcements.component";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SetPasswordComponent } from "./pages/set-password/set-password.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { StyleGuideComponent } from "./pages/style-guide/style-guide.component";
// Services
import { ResizeService } from "./services/resize.service";
import { CookieService } from "ngx-cookie-service";
import { NavigationService } from "./components/navigation/navigation.service";
import { TitleService } from "./services/title.service";

// Routing
import { AppRouting } from "./app.routing";

// Translate
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { environment } from "src/environments/environment";

// import { enableProdMode } from "@angular/core";
// import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import { CustomImageModule } from "./pages/configuration/custom-image/custom-image.module";
import { SizeDetectorComponent } from "./components/shared/size-detector/size-detector.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { Constants } from "src/app/constants/constants";
//
Sentry.init({
    dsn: "https://9597995b943f4224bd048227dbf8df45@o1341623.ingest.sentry.io/6618375",
    integrations: [
        // Registers and configures the Tracing integration, which automatically instruments your application to monitor its performance, including custom Angular routing instrumentation
        new BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation
        })
    ],
    environment: environment.production ? "production" : "dev"
});

const moreImports = [];

@NgModule({
    imports: [
        ...moreImports,
        LiveEventsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
        NgSelectModule,
        NgxSliderModule,
        HttpClientModule,
        FontAwesome,
        SharedModule,
        NgbModule,
        ModalModule,
        BroadcastersModule,
        DashboardModule,
        SearchModule,
        SourcesModule,
        EventsModule,
        ScteModule,
        GridsModule,
        ApiKeysModule,
        ChannelsModule,
        TargetsModule,
        ClustersModule,
        TranscodingProfilesModule,
        PidMappingsModule,
        EventsManagementModule,
        TagsModule,
        SshKeysModule,
        AwsAccountsModule,
        AzureAccountsModule,
        GcpAccountsModule,
        LinodeAccountsModule,
        NotificationsModule,
        AutomationModule,
        ReportsModule,
        AnnouncementsConfigurationsModule,
        RemoteAccessModule,
        MyAccountModule,
        UsersModule,
        SSOsModule,
        UserGroupsModule,
        RolesModule,
        AccountsModule,
        IoServersModule,
        MapModule,
        IncidentsModule,
        SharedPagesModule,
        CustomImageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DragulaModule.forRoot(),
        TourMatMenuModule,
        MatTooltipModule,
        RecaptchaV3Module,
        AppRouting
    ],
    declarations: [
        AppComponent,
        SizeDetectorComponent,
        TopbarComponent,
        NavigationComponent,
        CriticalAnnouncementsComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SetPasswordComponent,
        NotFoundComponent,
        StyleGuideComponent
    ],
    providers: [
        Title,
        TitleService,
        ResizeService,
        CookieService,
        NavigationService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: "dynamic" } },
        DecimalPipe,
        TitleCasePipe,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler()
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
                /* */
            },
            deps: [Sentry.TraceService],
            multi: true
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.production ? Constants.RECAPTCHA_PROD_KEY : Constants.RECAPTCHA_DEV_KEY
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    // Diagnostic only: inspect router configuration
    constructor(/*router: Router*/) {
        // Use a custom replacer to display function names in the route configs
        // const replacer = (key, value) => (typeof value === "function" ? value.name : value);
        // console.log("Routes: ", JSON.stringify(router.config, replacer, 2));
    }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
