import { Component, Input } from "@angular/core";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-live-event-sources-status-column",
    templateUrl: "./live-event-sources-status-column.component.html",
    styleUrls: ["./live-event-sources-status-column.component.scss"]
})
export class LiveEventSourcesStatusColumnComponent {
    @Input() sources: Source[] = [];
}
