import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { SetPasswordService } from "./set-password.service";
import { LoginService } from "../login/login.service";
import { NgForm } from "@angular/forms";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-set-password",
    templateUrl: "./set-password.component.html",
    styleUrls: ["./set-password.component.scss"]
})
export class SetPasswordComponent {
    password: string;
    confirmPassword: string;
    token: string;
    error: string;
    customError: string;
    done = false;
    loading = false;
    submitted = false;

    @ViewChild("form", { static: true }) form: NgForm;

    constructor(
        private router: Router,
        private setPasswordService: SetPasswordService,
        private loginService: LoginService,
        public appService: AppService
    ) {}

    async onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        if (this.password !== this.confirmPassword) {
            this.form.controls.confirmPassword.setErrors({ passwordMismatch: true });
            return;
        }

        this.loading = true;
        this.setPasswordService.set(this.password).subscribe(
            () => {
                const redirect = this.loginService.redirectUrl
                    ? this.router.parseUrl(this.loginService.redirectUrl)
                    : "/dashboard";
                // Redirect the user
                this.router.navigateByUrl(redirect);
            },
            error => {
                this.error = error.statusText;
                this.loading = false;
            }
        );
    }
}
