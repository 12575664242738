<form id="form" #form="ngForm">
    <div class="form-group">
        <label for="stages">{{ "STAGES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
        <div>
            <table class="table table-sm bordered m-0">
                <thead>
                    <tr>
                        <th scope="col" class="" style="width: 50%">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-80px ellipsis" title="{{ 'DURATION' | translate }}">
                            {{ "DURATION" | translate }} [{{ "MINUTES" | translate }}]
                        </th>
                        <th scope="col" class="w-150px ellipsis" title="{{ 'START_TIME' | translate }}">
                            {{ "START_TIME" | translate }}
                        </th>
                        <th scope="col" class="w-60px ellipsis" title="{{ 'FORCE' | translate }} ({{ 'SLATE' | translate }})">
                            {{ "FORCE" | translate }} {{ "SLATE" | translate }}
                        </th>
                        <th scope="col" class="maxw-140px ellipsis" title="{{ 'PRE_ROLL_CLIP' | translate }}">
                            {{ "PRE_ROLL_CLIP" | translate }} ({{ "OPTIONAL" | translate }})
                        </th>
                        <th scope="col" class="w-40px text-end"></th>
                    </tr>
                </thead>
                <tbody *ngIf="stages.length > 0">
                    <tr *ngFor="let stage of stages; index as index">
                        <td>
                            <input
                                type="text"
                                placeholder="{{ 'STAGE_NAME' | translate }}"
                                id="stageName_{{ index }}"
                                name="stageName_{{ index }}"
                                [(ngModel)]="stage.name"
                                required
                                class="form-control form-control-sm form-control-xs"
                                [ngClass]="{ 'is-invalid': submitted && form.controls['stageName_' + index]?.errors }"
                                (change)="validateAndEmitStage(true)"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                placeholder="{{ 'DURATION' | translate }}"
                                min="0"
                                max="1440"
                                id="duration_{{ index }}"
                                name="duration_{{ index }}"
                                pattern="^[0-9]*$"
                                [(ngModel)]="stage.duration"
                                [required]="isStagingAuto"
                                class="form-control form-control-sm form-control-xs"
                                [ngClass]="{ 'is-invalid': submitted && form.controls['duration_' + index]?.errors }"
                                (change)="validateAndEmitStage()"
                            />
                        </td>
                        <td>
                            <span *ngIf="stage.daysFromEvent">(+{{ stage.daysFromEvent }} days) </span>
                            <span>{{ stage.startTime | date : "h:mm:ss a" }}</span>
                        </td>
                        <td>
                            <div class="form-check d-flex justify-content-center">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="forceslate_{{ index }}"
                                    name="forceslate_{{ index }}"
                                    [(ngModel)]="!!stage.force_channel_slate"
                                    (change)="validateAndEmitStage()"
                                />
                            </div>
                        </td>
                        <td>
                            <ng-select
                                class="form-control form-control-sm form-control-xs"
                                name="dropdown_{{ index }}"
                                [items]="clips | map : 'name' | filter : '*'"
                                [clearable]="true"
                                [(ngModel)]="stage.clip_id"
                                [disabled]="!index"
                                (change)="validateAndEmitStage()"
                            ></ng-select>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectStage(index)">
                                <fa-icon icon="minus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="2" class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addStage()">
                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="submitted && duplicateNamesIndex?.length > 0" class="status-error mb-3">
            {{ "STAGE_NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.
        </div>
        <div *ngIf="submitted && stages.length === 0" class="status-error mb-3">{{ "AT_LEAST_1_STAGE_IS_REQUIRED" | translate }}.</div>
    </div>
</form>
